import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Landing from "../../components/landingSection";
import icons from "../../DB/icon_list_DV";
import ImageRight from "../../components/imageRight";
import Image from "../../components/imageProcessing";
import Icon_list from "../../components/SSL_COMP/icon_list";
// import Packages from "../../DB/plan-card-wildcard";
import PlanCard from "../../components/plan_card";
import LI from "../../components/plan-li";
import Feature from "../../components/SSL_COMP/FeaturesWithoutIcon";
import TrustedBrands from "../../components/SSL_COMP/trustedbrands";
import NavBar from "../../components/SSL_COMP/SSL-nav-bar";
import { graphql, useStaticQuery } from "gatsby";

/* eslint react/prop-types: 0 */
function DV() {
	const data = useStaticQuery(graphql`
		query WildQuery {
			allProducts(filter: { productID: { in: [5, 7, 10] } }) {
				nodes {
					productID
					productGroupID
					name
					description
					annually
				}
			}
		}
	`);
	return (
		<Layout>
			<SEO
				keywords={[`ssl wildcard certificates`, `sl certificates wildcard`]}
				title="Wildcard SSL Certificates"
				lang="en"
				image="hostylus-card-preview.jpg"
				description="Find the best SSL Wildcard Certificates from the major certificate authorities. Compare each feature and price to find the best SSL wildcard certificate"
			/>

			<Landing
				image={<Image image={"sslLanding.png"} alt={"Wildcard SSL"} />}
				header={"Wildcard SSL"}
				sub_header={"Secure unlimited subdomains on a single certificate."}
				btnText={"pricing"}
				btnURL={"#pricing"}
				hideSecondary_btn={"hidden"}
			/>
			<NavBar />
			<section className="w-full py-10">
				<ImageRight
					header={"What is a Wildcard SSL Certificate?"}
					desc={
						<>
							<p>
								Wildcard SSL allows you to secure an unlimited number of
								subdomains on a single certificate. It’s a great solution for
								anyone who hosts or manages multiple sites or pages that exist
								on the same domain. The one-time cost of the certificate covers
								you for additional subdomains you may add in the future.
							</p>
							<p>
								Unlike a standard SSL Certificate that is issued to a single
								Fully Qualified Domain Name only, e.g. www.yourdomain.com, which
								means it can only be used to secure the exact domain to which it
								has been issued, a Wildcard SSL Certificate is issued to
								*.yourdomain.com, where the asterisk represents all possible
								subdomains.
							</p>
							<p>
								Wildcard SSL is an option available for DV and OV SSL
								Certificates.
							</p>
						</>
					}
					hideShopNow={"hidden"}
					hideArrow={"hidden"}
					hideLearnMore={"hidden"}
					image={
						<div className="shadow-xl w-max mx-auto mobile:w-4/5">
							<Image
								image={"ssl/secure.svg"}
								alt={"secured green bar lock"}
								className="shadow-2xl"
							/>
						</div>
					}
					btnLink="#"
				/>
			</section>
			<section className="w-full p-20  my-20 mobile:p-10">
				<div className=" mx-auto grid grid-cols-3 mobile:grid-cols-1">
					<Feature
						header={"Delivered in Minutes for Instant Protection"}
						desc={
							"The fastest and most affordable way to activate SSL protection for your website, issuance is quick and often fully automated."
						}
					/>
					<Feature
						header={"Trust Site Seal"}
						desc={
							"Our SSL Certificates come with a trust seal that has been proven to increase visitor confidence and customer conversions"
						}
					/>
					<Feature
						header={"Boost your Google Ranking"}
						desc={
							"Google uses SSL/HTTPS as a factor in determining search engine ranking. Add SSL today to help boost your Google ranking!"
						}
					/>
				</div>
			</section>
			<section className="w-full p-10  bg-gradient-to-r from-blue-600 via-blue-700 to-blue-900 my-20 mobile:p-10">
				<section
					id="pricing"
					className="max-w-5xl mx-auto my-16 mobile:pb-3 tablet:mx-5"
				>
					<h2 className="text-4xl text-white font-bold text-center">
						Certificate Pricing
					</h2>
					<div className="grid grid-cols-3 gap-6 flex justify-items-stretch mt-6 max-w-4xl mx-auto mobile:grid-cols-1 tablet:grid-cols-2 tablet:gab-2 mobile:justify-items-center py-8">
						{data.allProducts.nodes.map(function (items, key) {
							var stripedHtml = items.description.replace(/<[^>]+>/g, "");
							var description = stripedHtml.split("\n");
							return (
								<PlanCard
									key={key}
									price={items.annually}
									header={items.name}
									productID={items.productID}
									perWhat={"yr"}
									myItems={
										<ul
											style={{
												listStyleImage:
													"url('https://ik.imagekit.io/softylus/tick_PLS8tkQNa.jpg')",
												listStylePosition: "inside",
											}}
											className="list-disc"
										>
											{description.slice(1).map((listItem, listIndex) => (
												<LI
													key={listIndex}
													LI={listItem}
													textSize={"text-sm"}
												/>
											))}
										</ul>
									}
								/>
							);
						})}
					</div>
				</section>
			</section>
			<section className="mx-auto text-center py-10">
				<div>
					<p className="text-3xl font-bold w-full px-10">
						There&apos;s also a lot more reasons why you should consider adding
						SSL to your website:
					</p>
				</div>
				<Icon_list icons={icons} columns={"4"} />
			</section>

			<TrustedBrands />
		</Layout>
	);
}

export default DV;
